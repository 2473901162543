/* global dataLayer, homeLanguage */
'use strict';

var Cookies = global.Cookies = require('js-cookie/src/js.cookie'); // for manipulating / retrieving cookies

/**
 * Country Selector
 * This module can be used to facilitate the country selection dialog
 * if the site being browsed is for a different country than the users
 * GeoIP location
 */

const cookieName = 'countrySelectionCookie';

/**
 * Get Home Country Code
 *
 * @returns {string} Home Country Code
 */
function getVisitingCountry() {
    return $('.country-language-selection-overlay').attr('data-country-selector-visiting');
}

/**
 * Show Overlay
 */
function showOverlay() {
    /**
     * Show the Background
     */
    $('.country-selection-background').show();
    var countrySelectorOverlay = $('.country-language-selection-overlay');

    countrySelectorOverlay.css({
        visibility: 'visible'
    });
}

/**
 * Hide Overlay
 */
function hideOverlay() {
    $('.country-selection-background').hide();
    $('.country-language-selection-overlay').css({
        visibility: 'hidden'
    });
}

/**
 * Close the Overlay and Cookie the user for the home country
 *
 * @param {event} event DOM Event
 */
function closeOverlay(event) {
    event.preventDefault();

    /**
     * Get the country code for the home country
     */
    var visitingCountryCountryCode = getVisitingCountry();

    Cookies.set(cookieName, visitingCountryCountryCode, { expires: 30 });

    hideOverlay();
}

/**
 * Go to Country
 * Cookie the user and follow the link
 * @param {string} selectedElement Optional parameter, eg. URL string
 */
function goToCountry(selectedElement) {
    Cookies.set(cookieName, $(selectedElement).attr('data-country-code'), { expires: 30 });
    if (typeof selectedElement !== 'undefined' && typeof selectedElement.href !== 'undefined') {
        window.location = selectedElement.href;
    }
}

/**
 * Go to Home Country
 * Cookie the user and follow the link
 */
function goToVisitingCountry() {
    Cookies.set(cookieName, getVisitingCountry(), { expires: 30 });
}

/**
 * Has Popup Modal URL
 * grabs a tag by class, then on click adds href of the country's url
 * and dynamically renders title, content and buttons
 */
function getPopUpModalURL() {
    $('.js-action__showpopupmodaltoexternalsite').on('click', function (e) {
        e.preventDefault();
        var externalUrl = $(this).data('url');
        $.modal({
            title: window.resources.leavingSiteModal.title,
            content: `<p>${window.resources.leavingSiteModal.body}</p><p>${window.resources.leavingSiteModal.proceed}</p>`,
            modalSizeClass: 'modal-md',
            buttons: [
                `<a href="${externalUrl}" class="btn btn-primary btn-country__link" role="button" target="_blank">${window.resources.leavingSiteModal.buttonOK}</a>`,
                `<button type="button" class="btn btn-primary d-block" data-dismiss="modal">${window.resources.leavingSiteModal.buttonCancel}</button>`
            ]
        });
    });
}


module.exports = function () {
    getPopUpModalURL();
    var countrySelectorOverlay = $('.country-language-selection-overlay');

    /**
     * Checks
     * =========================
     */

    /**
     * If the overlay doesn't exist, return because there is nothing for us to do
     */
    if (countrySelectorOverlay.length <= 0) {
        return;
    }

    /**
     * Check for Country Selection Cookie
     * If cookie exists and matches the visiting country, return
     */
    if (Cookies.get(cookieName) !== undefined) {
        var cookieCountryCode = Cookies.get(cookieName);

        if (cookieCountryCode === getVisitingCountry()) {
            return;
        }
    }

    /**
     * Event Listeners
     * =========================
     */
    var closeIcon = countrySelectorOverlay.find('.js-action__closeoverlay');
    var countryLink = countrySelectorOverlay.find('.js-action__gotocountry');
    var viewAllLink = countrySelectorOverlay.find('.js-action__viewall');
    var overlayBackground = $('.country-selection-background');

    closeIcon.on('click', closeOverlay);
    // X1-18911 When user gets to language/shipping selection only refresh page if changed destination otherwise close overlay without refreshing page to keep query string
    countryLink.each(function () {
        var $thisLink = $(this);
        var visitingCountryLang = $thisLink.attr('data-visiting-language');
        if ($thisLink[0].hostname === window.location.hostname && visitingCountryLang === homeLanguage) {
            $thisLink.on('click', closeOverlay);
        } else if ($thisLink[0].hostname === window.location.hostname && visitingCountryLang !== homeLanguage) {
            $thisLink.href = $thisLink[0].href + window.location.search;
            $thisLink.on('click', function () {
                event.preventDefault();
                goToCountry($thisLink);
            });
        } else {
            $thisLink.on('click', goToCountry);
        }
    });
    viewAllLink.on('click', goToVisitingCountry);
    overlayBackground.on('click', closeOverlay);

    /**
     * Show Overlay
     * =========================
     */
    showOverlay();
};
