'use strict';

/**
 * Show the Tier Expiration Warning message if the user's expiration date is within 30 days
 */
function showTierExpirationWarning() {
    const $expirationWarning = $('.js-tier-expiration-warning');
    // expiration warning will not be present if user is not in an expirable tier
    if (!$expirationWarning.length) {
        return;
    }

    let expirationDate = new Date($expirationWarning.data('tier-expiration-date'));
    let alertPeriod = $expirationWarning.data('tier-expiration-alert-period');
    let timeDiff = (expirationDate - new Date()) / (1000 * 3600 * 24); // convert millis to days
    if (timeDiff <= alertPeriod && timeDiff > 0) { // inbetween expiration date and 30 till
        $expirationWarning.removeClass('d-none');
    }
}

/**
 * Loads Rewards Tracker StepBar
 * @param {Object} $container - jQuery container object
 */
function loadRewardsTracker($container) {
    // eslint-disable-next-line no-param-reassign
    $container = ($container && $container.length) ? $container : $(document);
    let $rewardsTracker = $container.find('.js-rewards-tracker');

    if ($rewardsTracker.length) {
        // Initialize the Rewards Tracker Circular Progress Indicator
        if ($('.rewards-tracker__redemption-progress').length) {
            $rewardsTracker.find('.progress').progressArc();
        }

        showTierExpirationWarning();
    }
}

module.exports = {
    loadRewardsTracker: loadRewardsTracker
};
